import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from '@sbt-suite/components';
import { WINDOW } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
import { RolesService } from '../services/roles/roles.service';

export const permissaoRotaGuard: CanActivateFn = (route) => {
    const rolesService = inject(RolesService);
    const localStorage = inject(LocalStorageService);
    const window = inject(WINDOW);
    const routeRoles = route.data['roles'] as string[];
    const router = inject(Router);

    // quando desenvolvimento para dar um bypass
    if (!environment.USE_BACKEND) {
        return true;
    }

    if (!localStorage.getItem('appToken')) {
        localStorage.clear();

        window.location.href = environment.URL_LOGIN;
        return false;
    }

    if (!routeRoles || routeRoles.length === 0) {
        return true; // Se não houver roles definidas, permite o acesso
    }

    // Verifica se o usuário tem pelo menos uma das roles necessárias
    const temPermissao = rolesService.hasPermission(routeRoles);

    if (!temPermissao) {
        router.navigate(['/nao-autorizado']);
        return false;
    }

    return true;
};
