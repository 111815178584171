export const menuRoles = {
    itensMenu: {
        solicitacoes: {
            roles: [
                'PORTAL-DE-PROJETOS_SOLICITACOES_DELETAR',
                'PORTAL-DE-PROJETOS_SOLICITACOES_VISUALIZAR',
                'PORTAL-DE-PROJETOS_SOLICITACOES_SALVAR'
            ],
            solicitacoes: {
                visualizar: ['PORTAL-DE-PROJETOS_SOLICITACOES_VISUALIZAR'],
                salvar: ['PORTAL-DE-PROJETOS_SOLICITACOES_SALVAR'],
                deletar: ['PORTAL-DE-PROJETOS_SOLICITACOES_DELETAR']
            }
        },
        parametrizacoes: {
            roles: [
                'PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR',
                'PARAMETRIZACAO_AREA-EXECUTORA_SALVAR',
                'PARAMETRIZACAO_AREA-EXECUTORA_DELETAR',
                'PARAMETRIZACAO_CATEGORIA-DO-PROJETO_SALVAR',
                'PARAMETRIZACAO_CATEGORIA-DO-PROJETO_DELETAR',
                'PARAMETRIZACAO_CATEGORIA-DO-PROJETO_VISUALIZAR',
                'PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_DELETAR',
                'PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_SALVAR',
                'PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_VISUALIZAR'
            ],
            areaExecutora: {
                visualizar: ['PARAMETRIZACAO_AREA-EXECUTORA_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_AREA-EXECUTORA_SALVAR'],
                deletar: ['PARAMETRIZACAO_AREA-EXECUTORA_DELETAR']
            },
            categoriaDeProjeto: {
                visualizar: ['PARAMETRIZACAO_CATEGORIA-DO-PROJETO_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_CATEGORIA-DO-PROJETO_SALVAR'],
                deletar: ['PARAMETRIZACAO_CATEGORIA-DO-PROJETO_DELETAR']
            },
            taxasParametrizaveis: {
                visualizar: ['PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_SALVAR'],
                deletar: ['PARAMETRIZACAO_TAXAS-PARAMETRIZAVEIS_DELETAR']
            }
        },
        grupoQualitativo: {
            roles: [
                'GRUPOQUALITATIVO_CRITERIOS_VISUALIZAR',
                'GRUPOQUALITATIVO_CRITERIOS_SALVAR',
                'GRUPOQUALITATIVO_CRITERIOS_DELETAR',
                'GRUPOQUALITATIVO_GRUPOS_VISUALIZAR',
                'GRUPOQUALITATIVO_GRUPOS_SALVAR',
                'GRUPOQUALITATIVO_GRUPOS_DELETAR',
                'GRUPOQUALITATIVO_QUESTIONARIO-QUALITATIVO_VISUALIZAR',
                'GRUPOQUALITATIVO_QUESTIONARIO-QUALITATIVO_SALVAR',
                'GRUPOQUALITATIVO_QUESTIONARIO-QUALITATIVO_DELETAR'
            ],
            criterios: {
                visualizar: ['GRUPOQUALITATIVO_CRITERIOS_VISUALIZAR'],
                salvar: ['GRUPOQUALITATIVO_CRITERIOS_SALVAR'],
                deletar: ['GRUPOQUALITATIVO_CRITERIOS_DELETAR']
            },
            grupos: {
                visualizar: ['GRUPOQUALITATIVO_GRUPOS_VISUALIZAR'],
                salvar: ['GRUPOQUALITATIVO_GRUPOS_SALVAR'],
                deletar: ['GRUPOQUALITATIVO_GRUPOS_DELETAR']
            },
            questionarioQualitativo: {
                visualizar: ['GRUPOQUALITATIVO_QUESTIONARIO-QUALITATIVO_VISUALIZAR'],
                salvar: ['GRUPOQUALITATIVO_QUESTIONARIO-QUALITATIVO_SALVAR'],
                deletar: ['GRUPOQUALITATIVO_QUESTIONARIO-QUALITATIVO_DELETAR']
            }
        },
        template: {
            roles: [
                'TEMPLATE_UPLOAD-DE-ARQUIVOS_VISUALIZAR',
                'TEMPLATE_UPLOAD-DE-ARQUIVOS_SALVAR',
                'TEMPLATE_UPLOAD-DE-ARQUIVOS_DELETAR'
            ],
            uploadarquivos: {
                visualizar: ['TEMPLATE_UPLOAD-DE-ARQUIVOS_VISUALIZAR'],
                salvar: ['TEMPLATE_UPLOAD-DE-ARQUIVOS_SALVAR'],
                deletar: ['TEMPLATE_UPLOAD-DE-ARQUIVOS_DELETAR']
            }
        }
    }
};

/** Roles personalizadas conforme precisar em alguma aplicação
 * abaixo alguns exemplos
 */
export const rolesPersonalizadas = {};
